import 'core-js/stable'
import React from 'react'
import 'regenerator-runtime/runtime'
import Loader from 'components/Common/Loader'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import * as PXBThemes from '@pxblue/themes/react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import "./app.css";

const TaskScreen = React.lazy(() => import('components/TaskScreen'))
const CluesScreen = React.lazy(() => import('components/CluesScreen'))
const Rewardcreen = React.lazy(() => import('components/Rewardcreen'))
const QuizScreen = React.lazy(() => import('components/QuizScreen'))
const ClueListScreen = React.lazy(() => import('components/ClueListScreen'))
const LoadingScreen = React.lazy(() => import('components/LoadingScreen'))
const ActivityScreen = React.lazy(() => import('components/ActivityScreen'))
const StartScreen = React.lazy(() => import('components/StartScreen'))
const TaskListScreen = React.lazy(() => import('components/TaskListScreen'))
const VideoScreen = React.lazy(() => import('components/VideoScreen'))
const CompletionScreen = React.lazy(() => import('components/CompletionScreen'))

 

const App = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <Router>
        <Switch>
          <Route exact path='/'>
            <LoadingScreen />
          </Route>
          <Route exact path='/ActivityScreen/:id'>
            <ActivityScreen />
          </Route>
          <Route exact path='/ClueListScreen'>
            <ClueListScreen />
          </Route>
          <Route exact path='/TaskScreen/:id'>
            <TaskScreen />
          </Route>
          <Route exact path='/QuizScreen/:id'>
            <QuizScreen />
          </Route>
          <Route exact path='/CluesScreen/:id'>
            <CluesScreen />
          </Route>
          <Route exact path='/Rewardscreen/:id'>
            <Rewardcreen />
          </Route>
          <Route exact path='/StartScreen'>
            <StartScreen />
          </Route>
          <Route exact path='/CompletionScreen'>
            <CompletionScreen />
          </Route>
          <Route exact path='/VideoScreen/:id'>
            <VideoScreen />
          </Route>
     
          <Route exact path='/TaskListScreen'>
            <MuiThemeProvider theme={createMuiTheme(PXBThemes.blue)}>
              <TaskListScreen />
            </MuiThemeProvider>
          </Route>
      
        </Switch>
      </Router> 
    </React.Suspense>
  )
}

export default App